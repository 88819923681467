import { DateTime } from 'luxon'
import Entity from './Entity'
import ProductEntity from '~/entities/Product.entity'

type Author = {
  id: number
  email: string
  fullName: string
  firstName: string
  shortDescription: string
}
class ProductFeedback extends Entity {
  title?: string
  rating = 0
  comment = ''
  product = ''
  productVariantCode?: string
  email?: string
  name?: string
  pros = ''
  cons = ''
  youtube = ''
  bought = true
  parent: number | string = 0
  createdAt?: string
  children?: any[]
  reacted?: string | undefined
  likes?: any[]
  files?: any[]
  id?: number
  author?: Author
  status?: string
  reviewSubject?: string | ProductEntity
  totalLikes?: number

  constructor(payload: Partial<ProductFeedback>) {
    super(payload)
    Object.assign(this, payload)
  }

  setProduct(code: string) {
    this.product = code
  }

  setVariant(code: string) {
    this.productVariantCode = code
  }

  setEmail(email: string) {
    this.email = email
  }

  setYouTubeVideo(link: string) {
    this.youtube = link
  }

  setName(name: string) {
    this.name = name
  }

  getName() {
    return this.name || this.author?.firstName || ''
  }

  getShortDescription() {
    return this.author?.shortDescription || ''
  }

  setParent(parentId: number) {
    this.parent = parentId
  }

  setComment(comment: string) {
    this.comment = comment
  }

  getDate() {
    return this.createdAt
      ? DateTime.fromFormat(this.createdAt, 'yyyy-LL-dd HH:mm:ss').toFormat(
        'dd.LL.yyyy'
      )
      : ''
    // return this.createdAt
  }

  getYouTubeLink() {
    const regex =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i
    const matches = this.youtube.match(regex)

    if (matches && matches[1]) {
      return 'https://www.youtube.com/embed/' + matches[1]
    }

    return null
  }

  textWithLink(text: string) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
    const replacedText = text.replaceAll('\n', '<br />')
    return replacedText.replaceAll(
      urlPattern,
      '<a href="$1" class="text-green-ibis" target="_blank" rel="noopener noreferrer">$1</a>'
    )
  }

  hasMedia() {
    return this.files && !!this.files?.length
  }

  hasChildren() {
    return this.children && !!this.children?.length
  }

  getMedia() {
    return this.files || []
  }
}

export default ProductFeedback
